/*
* @Author: Abhi Bhatt
* @Date:   2020-06-11 15:19:17
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-09-09 13:42:51
*/
export const API_URL = '//admin.techtopind.net/api';
export const EXTERNAL_API_URL = '//admin.techtopind.net/external-api';
//export const GOOGLE_MAP_API_KEY = 'AIzaSyAI53eOM59m8Fyg6dL1o08qG79HEcU8Gww';
export const GOOGLE_MAP_API_KEY = 'AIzaSyCn9SOnEvUgW6hrahegzhmChg_S5i1isBk';
export const FACILITIES = [{'name':'Techtop Industries, Inc.','lat':'33.9570388','lng':'-84.2005589'},{'name':'260 Park Loop Rd','lat':'37.9765825','lng':'-85.6642561'},{'name':'8417 SW 23rd Pl','lat':'35.439835','lng':'-97.661899'}];
export const FREIGHTVIEW_API_V1 = 'https://www.freightview.com/api/v1.0/';
export const FREIGHTVIEW_API_KEY = 'NVgxcUFyMnpqQnBpTjVLR0ZUNlZqamFUbXNxcGoyMTo=';