/*
* @Author: abhi
* @Date:   2024-07-24 18:12:11
* @Last Modified by:   abhi714
* @Last Modified time: 2024-09-03 10:22:49
*/
import axios from 'axios';
import { EXTERNAL_API_URL, FREIGHTVIEW_API_V1, FREIGHTVIEW_API_KEY } from '../../constants.js';

class ShipmentService {
	async checkShipmentData(formData) {
		const url = `${EXTERNAL_API_URL}/get-shipment/`;
		const headers = {
			'Content-Type':'multipart/form-data',
			'Access-Control-Allow-Origin':'*'
		}
		return axios({
			url:url,
			method: 'post',
			crossDomain:true,
			data: formData,
			config:{headers:headers},
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async getCartonDetail(formData) {
		const url = `${EXTERNAL_API_URL}/get-carton-detail/`;
		const headers = {
			'Content-Type':'multipart/form-data',
			'Access-Control-Allow-Origin':'*'
		}
		return axios({
			url:url,
			method: 'post',
			crossDomain:true,
			data: formData,
			config:{headers:headers},
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async auth() {
		const url = `${EXTERNAL_API_URL}/iopgps-auth/`;
		const headers = {
			'Content-Type': 'application/json'
		};
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			config:{headers:headers},
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async trackHistory(imei, accessToken, time=null) {
		const url = `${EXTERNAL_API_URL}/iopgps-track-history/`;
		const headers = {
			'Content-Type':'multipart/form-data',
			'Access-Control-Allow-Origin':'*'
		}
		const now = new Date();
		const startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
		const startTimeInSeconds = Math.floor(startTime.getTime() / 1000);
		time = (time===null) ? startTimeInSeconds : time;
		const data = {
			accessToken : accessToken,
			imei: imei,
			startTime: time
		}
		return axios({
			url:url,
			method: 'post',
			crossDomain:true,
			data: data,
			config:{headers:headers},
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async getShipmementStaus(ref) {
		const url = `${FREIGHTVIEW_API_V1}shipments/?ref=${ref}`;
		const headers = {
			'Access-Control-Allow-Origin':'*',
			'Authorization': `Basic ${FREIGHTVIEW_API_KEY}`
		}
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			headers:headers,
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}
}
export default ShipmentService;