/*
 * @Author: abhi
 * @Date:   2024-07-24 16:26:50
 * @Last Modified by:   abhi714
 * @Last Modified time: 2024-09-07 12:06:13
 */
import React from "react";
import moment from "moment";
import { GOOGLE_MAP_API_KEY, FACILITIES } from "../../constants.js";
import {
  GoogleMap,
  LoadScript,
  DirectionsService,
  DirectionsRenderer,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import ShipmentService from "./../../services/ShipmentService/ShipmentService";
import car_icon from "../../images/car.png";
import start_icon from "../../images/start_point.png";
import end_icon from "../../images/end_point.png";
import loading_icon from "../../images/loading.gif";
import carton_icon from "../../images/carton.png";
import truck_icon from "../../images/truck.png";
import l_symbol_icon from "../../images/l_icon.png";
import start_symbol from "../../images/start_symbol.png";

class TrackShipmentOld extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      isValid: false,
      iopgps_accessToken: null,
      shipmentData: [],
      imei: null,
      coordinates: [],
      directionsRequest: null,
      directionsResponse: null,
      showInfoWindow: false,
      infoWindowPosition: null,
      infoWindowContent: "",
      isTracking: false,
      isShowMap: false,
      isLoadingMap: false,
      isReturn: false,
      shipDate: null,
      cartonDetails: {},
      selectedCarton: null,
      selectedShipmentNumber: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.trackShipment = this.trackShipment.bind(this);
    this.getCartonDetail = this.getCartonDetail.bind(this);
    this.shipmentService = new ShipmentService();
    this.renderShipmentResult = this.renderShipmentResult.bind(this);
    this.handleDirectionsCallback = this.handleDirectionsCallback.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onInfoWindowCloseClick = this.onInfoWindowCloseClick.bind(this);
    this.handleGpsIdChange = this.handleGpsIdChange.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);

    this.bottomRef = React.createRef();
  }

  componentDidMount(prevProps, prevState) {
    if (this.state.shouldScrollToBottom) {
      this.scrollToBottom();
      this.setState({ shouldScrollToBottom: false }); // Reset the flag
    }
  }

  scrollToBottom() {
    if (this.bottomRef && this.bottomRef.current) {
      window.scrollTo({
        top: this.bottomRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }

  async getAccessToken() {
    try {
      const response = await this.shipmentService.auth();
      if (response.status === 200 && response.data.status === "success") {
        this.setState(
          { iopgps_accessToken: response.data.result.accessToken },
          this.trackShipment
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getCartonDetail(imei = null) {
    let cartonDetails = {};
    let carton_name = null;
    this.setState({ isLoadingMap: true });

    imei = imei !== null ? imei : this.state.imei;
    
    let item = this.getShipDataByGpsId(imei);
    if (item) {
        item.pallets.forEach((value) => { // Corrected map to forEach
            if (value.gps_id === imei) {
                carton_name = value.name;
                return;
            }
        });

        const shipment_number = item.shipment_number;
        const response = await this.shipmentService.getCartonDetail({ shipment_number: shipment_number });

        if (response.data.status === 'success') {
            //cartonDetails[imei] = {};
            cartonDetails[imei] = response.data.result; // Use imei as the key
        }

        this.setState({ 
            cartonDetails: cartonDetails,
            selectedCarton: carton_name,
            selectedShipmentNumber: shipment_number,
            isLoadingMap: false // Remember to stop loading
        });

        this.trackShipment(imei);
    }
  }

  async trackShipment(imei = null) {
    if (!this.state.iopgps_accessToken) {
      await this.getAccessToken();
    } else {
      try {
        this.setState({ isLoadingMap: true });
        //let imei = this.state.imei !== null ? this.state.imei : '865468051130535';
        imei = imei !== null ? imei : this.state.imei;
        let item = this.getShipDataByGpsId(imei);
        let start_time = item.shipped_date;
        const response = await this.shipmentService.trackHistory(
          imei,
          this.state.iopgps_accessToken,
          start_time
        );

        if (
          response.status === 200 &&
          response.data.status === "success" &&
          response.data.result.data.length > 0
        ) {
          const data = response.data.result.data;
          // Convert the data to an array of coordinates
          const coordinates = data.map((item) => ({
            code: item.code,
            course: item.course,
            gpsTime: item.gpsTime,
            imei: item.imei,
            lat: parseFloat(item.lat), // Ensure lat is a number
            lng: parseFloat(item.lng), // Ensure lng is a number
            positionType: item.positionType,
            speed: item.speed,
          }));

          this.setState(
            { coordinates: coordinates, shouldScrollToBottom: true },
            this.calculateRoute
          );
          setTimeout(() => {
            this.setState({ isShowMap: true, isLoadingMap: false });
          }, 2000);
        } else {
          this.setState({ coordinates: [], isShowMap: false, isLoadingMap: false });
        }
      } catch (error) {
        console.error(error);
        this.setState({ coordinates: [], isShowMap: false });
      }
    }
  }

  handleChange(field, e) {
    let fields = { ...this.state.fields };
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["sale_order_number"]) {
      formIsValid = false;
      errors["sale_order_number"] = "Please enter sales order number.";
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleSubmit(e) {
    let errors = {};
    let data = [];
    let imei = "";
    this.setState({
      shipmentData: [],
      coordinates: [],
      errors,
      isValid: false,
      directionsResponse: null,
    });
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ isTracking: true });
      this.shipmentService
        .checkShipmentData(this.state.fields)
        .then((response) => {
          this.setState({ isTracking: false });
          if (response.status === 200 && response.data.status === "success") {
            data = response.data.result;
            Object.entries(data).forEach(([key, value]) => {
              value['shipment_status'] = '';
              this.shipmentService.getShipmementStaus(value.sales_order_number).then((res) => {
                if(res.data.total > 0) {
                  value['shipment_status'] = res.data.shipments[0].status;
                }
              });
              Object.entries(value.pallets).forEach(([key1, value1]) => {
                if (imei === "" && value1["gps_id"] !== "") {
                  imei = value1["gps_id"];
                }
              });
            });
            console.log(data);
            this.setState({ shipmentData: data });
            this.setState({ imei: imei });
            this.setState({ isValid: true }, this.getCartonDetail);
          } else {
            errors["sale_order_number"] = response.data.message;
            this.setState({ errors });
          }
        });
    }
  }

  calculateRoute() {
    const MAX_WAYPOINTS = 23; // Maximum allowed waypoints
    const MIN_DISTANCE = 12; // Minimum distance between origin and repeated origin coordinate
    let { coordinates } = this.state;
    let newCoordinates = [];
    const facilities = FACILITIES;
    const RADIUS_THRESHOLD = "0.50";
    let i = 0;
    let coAfter = 0;
    let shouldStop = false;

    if (coordinates.length > 1) {
      coordinates = [...coordinates];
      coordinates.shift();
      let origin = coordinates[0];
      this.setState({ shipDate: origin.gpsTime });
      for (i; i < coordinates.length; i++) {
        let distanceToFacility = this.haversineDistance(coordinates[i], origin);
        if (distanceToFacility >= 1) {
          i = i;
          break;
        }
      }
      if (i > 1) {
        for (var k = 0; k <= i; k++) {
          newCoordinates.push(coordinates[k]);
        }
      }
      for (i; i < coordinates.length; i++) {
        shouldStop = false;
        for (let j = 0; j < facilities.length; j++) {
          const distanceToFacility = this.haversineDistance(
            coordinates[i],
            facilities[j]
          );
          if (distanceToFacility <= RADIUS_THRESHOLD) {
            let shouldStop = true;
            break;
          }
          if (shouldStop) {
            break;
          } else {
            newCoordinates.push(coordinates[i]);
          }
        }
      }
      newCoordinates = newCoordinates.filter((coordinate, index, array) => {
        return (
          index === 0 ||
          !(
            coordinate.lat === array[index - 1].lat &&
            coordinate.lng === array[index - 1].lng
          )
        );
      });
      coAfter = Math.ceil(newCoordinates.length / 23);
      coordinates = [];
      coordinates.push(newCoordinates[0]);
      for (let l = 1; l <= 23; l++) {
        if (l === 23 && typeof newCoordinates[coAfter * l] === "undefined") {
          coordinates.push(newCoordinates[coordinates.length - 2]);
        } else {
          coordinates.push(newCoordinates[coAfter * l]);
        }
      }
      coordinates.push(newCoordinates[coordinates.length - 1]);
      this.setState({ coordinates: coordinates });

      let destination = coordinates[coordinates.length - 1];
      let waypoints = coordinates.slice(1, -1);

      // Limit waypoints to MAX_WAYPOINTS
      waypoints = waypoints.map((coord) => ({
        location: coord,
        stopover: false,
      }));

      this.setState({
        directionsRequest: {
          origin,
          destination,
          waypoints,
          travelMode: "DRIVING",
        },
        isReturn: shouldStop,
      });
    }
  }

  haversineDistance(coord1, coord2) {
    const toRadians = (degrees) => degrees * (Math.PI / 180);

    const lat1 = coord1.lat;
    const lon1 = coord1.lng;
    const lat2 = coord2.lat;
    const lon2 = coord2.lng;

    const R = 6371; // Radius of the Earth in kilometers
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // Distance in kilometers

    return distance;
  }

  handleDirectionsCallback(response) {
    if (response !== null) {
      if (response.status === "OK") {
        this.setState({
          directionsResponse: response,
        });
      } else {
        //console.log('response: ', response);
      }
    }
  }

  onMarkerClick(position, content) {
    this.setState({
      showInfoWindow: true,
      infoWindowPosition: position,
      infoWindowContent: content,
    });
  }

  onInfoWindowCloseClick() {
    this.setState({
      showInfoWindow: false,
      infoWindowPosition: null,
      infoWindowContent: "",
    });
  }

  handleGpsIdChange(e) {
    if(e.target.value) {
      this.setState({ isShowMap : false });
      this.setState({ imei: e.target.value });
      this.getCartonDetail(e.target.value);
    }
  }

  getShipDataByGpsId(gps_id = null) {
    if (gps_id !== null) {
      const { shipmentData } = this.state;
      for (const item of shipmentData) {
        for (const pallet of item.pallets) {
          if (pallet.gps_id === gps_id) {
            return item;
          }
        }
      }
    }
    return null;
  }

  renderMap() {
    const containerStyle = {
      width: "100%",
      height: "400px",
    };
    const { coordinates, isReturn } = this.state;
    let des_icon = isReturn ? car_icon : end_icon;
    const center = coordinates.length > 0 ? coordinates[0] : { lat: 0, lng: 0 };
    const zoom = 10;

    return (
      <React.Fragment>
        <div className="row justify-content-center mb-3">
          <div className="col-md-10">
            <article
              className="motors"
              style={{
                display:
                  this.state.isShowMap && !this.state.isLoadingMap
                    ? "block"
                    : "none",
              }}
            >
              <LoadScript googleMapsApiKey={GOOGLE_MAP_API_KEY}>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={zoom}
                  onLoad={() => this.forceUpdate()} // Ensure `window.google` is available
                >
                  {this.state.directionsRequest && (
                    <DirectionsService
                      options={this.state.directionsRequest}
                      callback={this.handleDirectionsCallback}
                    />
                  )}

                  {this.state.directionsResponse && (
                    <DirectionsRenderer
                      options={{
                        directions: this.state.directionsResponse,
                        suppressMarkers: true,
                        polylineOptions: {
                          strokeColor: "#fa4d09",
                          strokeOpacity: 0.8,
                          strokeWeight: 6,
                          icons: [
                            {
                              icon: {
                                path: window.google.maps.SymbolPath
                                  .FORWARD_CLOSED_ARROW, // This is the arrow symbol
                                scale: 2, // Adjust the size of the arrow
                                strokeColor: "#ffffff", // Color of the arrow
                              },
                              offset: "100%", // Position of the arrow along the path
                              repeat: "40px", // Repeat arrow every 100px
                            },
                          ],
                        },
                      }}
                    />
                  )}
                  {this.state.coordinates.length > 0 && window.google && (
                    <Marker
                      position={coordinates[0]}
                      icon={{
                        url: `${start_icon}`,
                        scaledSize: new window.google.maps.Size(40, 40),
                      }}
                      onClick={() =>
                        this.onMarkerClick(
                          coordinates[0],
                          this.renderCoordinateDetails(coordinates[0])
                        )
                      }
                    />
                  )}

                  {this.state.coordinates.length > 0 && (
                    <Marker
                      position={coordinates[coordinates.length - 1]}
                      icon={{
                        url: `${des_icon}`,
                        scaledSize: new window.google.maps.Size(40, 40),
                      }}
                      onClick={() =>
                        this.onMarkerClick(
                          coordinates[coordinates.length - 1],
                          this.renderCoordinateDetails(
                            coordinates[coordinates.length - 1]
                          )
                        )
                      }
                    />
                  )}

                  {this.state.showInfoWindow && (
                    <InfoWindow
                      position={this.state.infoWindowPosition}
                      onCloseClick={this.onInfoWindowCloseClick}
                    >
                      <div>{this.state.infoWindowContent}</div>
                    </InfoWindow>
                  )}
                </GoogleMap>
              </LoadScript>
            </article>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderCoordinateDetails(coordinate) {
    const formattedGpsTime = moment
      .unix(coordinate.gpsTime)
      .format("DD/MM/YYYY HH:mm");
    return (
      <div>
        <p>IMEI: {coordinate.imei}</p>
        <p>Latitude: {coordinate.lat}</p>
        <p>Longitude: {coordinate.lng}</p>
        <p>Speed: {coordinate.speed}</p>
        <p>Course: {coordinate.course}</p>
        <p>GPS Time: {formattedGpsTime}</p>
        <p>Position Type: {coordinate.positionType}</p>
      </div>
    );
  }

  renderShipmentResult() {
    let { shipmentData, isShowMap, shipDate } = this.state;
    return (
      <React.Fragment>
        <div className="row justify-content-center">
          <div className="col-md-10">
            <article className="motors">
              <div className="table-responsive">
                <table className="table mt-5">
                  <thead>
                    <tr>
                      <th className="text-center">Customer</th>
                      <th className="text-center">Shipment</th>
                      <th className="text-center">Date Shipped</th>
                      <th className="text-center">Carrier</th>
                      <th className="text-center">Tracking Number</th>
                      <th className="text-center">GPS Unit Status</th>
                      <th className="text-center">Pallet Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shipmentData.length > 0 ? (
                        shipmentData.map((shipment, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td className="text-center">{shipment['customer_name']}</td>
                              <td className="text-center">{shipment['shipment_number']}</td>
                              <td>{(shipDate !== null) ? moment(shipDate * 1000).format('DD/MM/YYYY') : '--/--/----'}</td>
                              <td className="text-center">{shipment['carrier_name']}</td>
                              <td className="text-center">{shipment['tracking_num'] ? shipment['tracking_num'] : shipment['pro_number']}</td>
                              <td className="text-center">{shipment['shipment_status']}</td>
                              <td className="text-center">
                                {
                                  Object.keys(shipment['pallets']).length >= 1 ? (
                                    <select className="form-control" value={this.state.imei} onChange={this.handleGpsIdChange}>
                                      <option value="">Select Pallet</option>
                                      {Object.keys(shipment['pallets']).map((key) => (
                                        <option key={key} value={shipment['pallets'][key]['gps_id']}>
                                          {shipment['pallets'][key]['name']}
                                        </option>
                                      ))}
                                    </select>
                                  ) : null
                                }
                              </td>
                            </tr>
                            {(typeof this.state.cartonDetails[this.state.imei] !== 'undefined' && shipment['shipment_number'] === this.state.selectedShipmentNumber) ?
                              <tr>
                                <td colSpan="7">
                                  <table className="carton-details">
                                    <thead>
                                      <tr>
                                        <td>
                                          <div className="track-shipment-main-div">
                                            <div className="track-pin-div">
                                              <img src={start_symbol} alt="" />
                                            </div>  
                                            <div className="track-carton-div">
                                              <img src={carton_icon} alt="" />{this.state.selectedCarton}
                                            </div>  
                                          </div>
                                        </td>
                                        <td>Description</td>
                                        <td style={{textAlign:'center'}}>Qty</td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Object.keys(this.state.cartonDetails[this.state.imei]).map((key) => (
                                        <tr key={key}>
                                          <td style={{width:'25%'}}>
                                            <div className="track-shipment-div">  
                                              <div className="track-delivery-van-div2">
                                                <div className="track-delivery-l-img">
                                                  <img src={l_symbol_icon} alt="" />
                                                </div>
                                                <div className="track-delivery-van-img">
                                                  <img src={truck_icon} alt="" />
                                                </div>  
                                                <div className="track-shipment-txt">
                                                  {this.state.cartonDetails[this.state.imei][key].model}
                                                </div>
                                              </div>
                                            </div> 
                                          </td>
                                          <td style={{width:'65%'}}>{this.state.cartonDetails[this.state.imei][key].description}</td>
                                          <td style={{width:'10%', textAlign:'center'}}>{this.state.cartonDetails[this.state.imei][key].quantity}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              : null
                            }
                          </React.Fragment>
                        ))
                    ) : (
                      <React.Fragment>
                        <tr>
                          <td colSpan="7" className="text-center">Data not found.</td>
                        </tr>
                      </React.Fragment>
                    )}
                  </tbody>
                </table>
              </div>
            </article>
          </div>
        </div>
        {this.state.isLoadingMap && (
          <React.Fragment>
            <article className="motors">
              <div className="row justify-content-center mb-3">
                <div className="col-md-10 d-flex justify-content-center">
                  <img src={loading_icon} />
                </div>
              </div>
            </article>
          </React.Fragment>
        )}
        {(!this.state.isLoadingMap && !this.state.isShowMap) && (
          <React.Fragment>
            <article className="motors">
              <div className="row justify-content-center mb-3">
                <div className="col-md-10 d-flex justify-content-center">
                  <h3>Device tracking history not found.</h3>
                </div>
              </div>
            </article>
          </React.Fragment>
        )}
        <div ref={this.bottomRef}></div>
        {this.renderMap()}
      </React.Fragment>
    );
  }

  render() {
    let { errors, isTracking } = this.state;
    return (
      <React.Fragment>
        <main role="main" className="middle-content-area">
          <div className="container-fluid">
            <section className="mt-2">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <article className="motors">
                    <div className="table-responsive">
                      <table className="table mt-5">
                        <thead>
                          <tr>
                            <th colSpan="2" className="text-center">
                              Enter your shipment number below
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <label
                                className="form-label"
                                htmlFor="sale_order_number"
                              >
                                Insert your shipment number
                              </label>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="sale_order_number"
                                placeholder="Shipment Number"
                                style={{ backgroundColor: "#FAFFBD" }}
                                id="sale_order_number"
                                onChange={(e) =>
                                  this.handleChange("sale_order_number", e)
                                }
                                defaultValue={
                                  this.state.fields["sale_order_number"]
                                }
                              />
                              <span style={{ color: "red" }}>
                                {errors["sale_order_number"]}
                              </span>
                            </td>
                          </tr>
                          <tr className="text-center">
                            <td colSpan="2">
                              If you do not know your shipment number, please
                              contact Customer Service
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </article>
                </div>
                <div className="col-md-12 d-flex justify-content-center mb-3">
                  <button
                    type="button"
                    className="btn btn-primary text-center"
                    id="trackButton"
                    style={{ fontSize: "1 em", marginLeft: "0" }}
                    onClick={this.handleSubmit}
                    disabled={isTracking}
                  >
                    {isTracking ? "Tracking..." : "Track Shipment"}
                  </button>
                </div>
              </div>
              {this.state.isValid && this.renderShipmentResult()}
            </section>
          </div>
          <div ref={this.bottomRef}></div>
        </main>
      </React.Fragment>
    );
  }
}

export default TrackShipmentOld;